<template>

  <div class="title">
    <div class="container">
      <h1>Dimeco Safe</h1>
    </div>
  </div>

  <section id="section_1">
    <div class="container">
      <div class="col-12 col-lg-10 m-auto">
        <div class="d-xl-flex flex-row gap-3 justify-content-between align-items-center w-100 ">
          <div class="c">
            <p>INCLUDES:</p>
            <ul>
              <li>alcohol breathalyzer</li>
              <li>an infrared thermometer</li>
            </ul>
          </div>
          <div class="c">
            <img src="../assets/photo-at.png" alt="">
          </div>
          <div class="c">
            <ul>
              <li>display</li>
              <li>micro-computer</li>
              <li>video camera</li>
              <li>card reader</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="section_2">
    <div class="container">
      <div class="col-md-10 m-auto">
        <div class="d-lg-flex flex-row gap-3 justify-content-between w-100 ">
          <h4>THE IDENTIFICATION PROCESS, ALCOHOL AND TEMPERATURE MEASUREMENTS TAKE ONLY ABOUT 10 SECONDS!</h4>
        </div>
      </div>
    </div>
  </section>

  <section id="section_3">
    <div class="container">
      <div class="col-md-10 m-auto">
        <ul>
          <li>Prohibition of access to the enterprise for employees in a state of alcohol intoxication or poor health</li>
          <li>Detection of unwanted persons using the face recognition system</li>
          <li>Control of the shift time of employees</li>
          <li>Prompt informing the responsible persons about violations</li>
          <li>Possibility to create a "virtual checkpoint"</li>
          <li>Possibility of combining alcohol / thermal checkers into a single network at the enterprise / city / region / country level.</li>
        </ul>

        <h4>Adjustable parameters:</h4>

        <ul>
          <li>Personal authentication</li>
          <li>Measurement of the alcohol vapors in the inhaled air</li>
          <li>Body temperature measurement</li>
          <li>Fixing the time of the test Options of connection:</li>
          <li>Autonomous mode</li>
          <li>Virtual checkpoint</li>
          <li>Integration with ASC</li>
          <li>Guest mode</li>
        </ul>
      </div>

    </div>
  </section>

  <section id="section_4">
    <div class="container">

      <div class="col-md-10 m-auto">
        <h4>A VIRTUAL CHECKPOINT</h4>

        <p>Dimeco SAFE identifies the employee and forms a timesheet based on entry and exit times and other valuable data.</p>

      </div>

      <div>
        <table style="width: 100%;">
          <tbody>
          <tr>
            <td style="width: 11.4437%; background: #f9fafb; color: #69b249;" rowspan="2"><span style="font-weight: 400;">DAY</span></td>
            <td style="width: 13.1291%; background: #f9fafb; color: #69b249;" rowspan="2"><span style="font-weight: 400;">NAME</span></td>
            <td style="width: 27.5164%; background: #f9fafb; color: #69b249;" colspan="4"><span style="font-weight: 400;">BEFORE SHIFT</span></td>
            <td style="width: 30.5492%; background: #f9fafb; color: #69b249;" colspan="4"><span style="font-weight: 400;">AFTER SHIFT</span></td>
            <td style="width: 4.36418%; background: #f9fafb; color: #69b249;" rowspan="2"><span style="font-weight: 400;">SHIFT DURATION</span></td>
          </tr>
          <tr>
            <td style="width: 7.79204%; background: #f9fafb; color: #69b249;"><span style="font-weight: 400;">FACE ID</span></td>
            <td style="width: 5.07667%; background: #f9fafb; color: #69b249;">
            <span style="font-weight: 400;">TIME</span>
            </td>
            <td style="width: 5.91936%; background: #f9fafb; color: #69b249;">
            <span style="font-weight: 400;">ALCOHOL</span>
            </td>
            <td style="width: 8.72835%; background: #f9fafb; color: #69b249;">
            <span style="font-weight: 400;">TEMPERATURE</span>
            </td>
            <td style="width: 7.41749%; background: #f9fafb; color: #69b249;"><span style="font-weight: 400;">FACE ID</span></td>
            <td style="width: 5.63845%; background: #f9fafb; color: #69b249;">
            <span style="font-weight: 400;">TIME</span>
            </td>
            <td style="width: 8.72835%; background: #f9fafb; color: #69b249;">
            <span style="font-weight: 400;">ALCOHOL</span>
            </td>
            <td style="width: 8.76493%; background: #f9fafb; color: #69b249;">
            <span style="font-weight: 400;">TEMPERATURE</span>
            </td>
          </tr>
          <tr>
            <td style="width: 11.4437%;">
            <span style="font-weight: 400;" id="date">21 AUG 2020</span>
            </td>
            <td style="width: 13.1291%;"><span style="font-weight: 400;">JOHN SMITH</span></td>
            <td style="width: 7.79204%;">YES</td>
            <td style="width: 5.07667%;">8&nbsp;am</td>
            <td style="width: 5.91936%;">0</td>
            <td style="width: 8.72835%;">NORMAL</td>
            <td style="width: 7.41749%;">YES</td>
            <td style="width: 5.63845%;">5&nbsp;pm</td>
            <td style="width: 8.72835%;">0</td>
            <td style="width: 8.76493%;">NORMAL</td>
            <td style="width: 4.36418%; ">&nbsp;8 HOURS</td>
          </tr>
          </tbody>
        </table>

      </div>

      <div class="row pt-5">

        <div class="col-lg-10 m-auto">
          <div class="row pb-4">
            <div class="col-lg-6">
              <img src="../assets/alcotermo-3-2.png" alt="">
              <div class="container bg-false text-center p-2">
                Regular
              </div>
            </div>
            <div class="col-lg-6">
              <img src="../assets/alcotermo-3-3.png" alt="">
              <div class="container bg-true text-center p-2">
                DIMECO
              </div>
            </div>
          </div>

          <p>Dimeco uses an innovative system to remove excess air, which makes testing in public as safe as possible.</p>
          <p>This ensures viruses and microbes don’t accumulate on the breathalyzer funnel, which would blow directly onto the next user.</p>

        </div>
      </div>


    </div>
  </section>

  <section id="section_5">
    <div class="container col-12 col-lg-8 m-auto col-xl-8">
      <div class="row">
        <div class="col-xl-7 d-flex flex-column justify-content-around align-items-center gap-3">

          <div class="card p-2">
              <div class="d-flex flex-row align-items-center">
                <div class="number">1</div>
                <div class="descr">Employee identification or guest entrance. "Authorized Person or Unauthorized Person".</div>
              </div>
          </div>

          <div class="card p-2">
              <div class="d-flex flex-row align-items-center">
                <div class="number">2</div>
                <div class="descr">Blowing the air into a safe Dimeco funnel - determining the presence of alcohol vapors in the exhaled air, Objective determination of body temperature.</div>
              </div>
          </div>

          <div class="card p-2">
              <div class="d-flex flex-row align-items-center">
                <div class="number">3</div>
                <div class="descr">Obtaining the access - in the absence of alcohol vapors in the exhaled air and at a body temperature of less than&nbsp;37˚.</div>
              </div>
          </div>

        </div>
        <div class="col-xl-5">
          <img src="../assets/photo-t11-tr.png" class="d-block m-auto" alt="">
        </div>
      </div>
    </div>
  </section>

  <section></section>

  <ContactForm />

</template>

<script>
import { useMeta } from 'vue-meta'
import { ContactForm } from '@/components/ContactForm.vue'

export default {
  name: 'DimecoSafePage',
  components: {
    ContactForm
  },
  setup() {
    useMeta({
      title: 'Dimeco Safe',
      meta: [
        { name: 'description', content: 'Dimeco Safe' },
        { name: 'keywords', content: 'Dimeco Safe' },
        { property: 'og:title', content: 'Dimeco Safe' },
        { property: 'og:description', content: 'Dimeco Safe' }
      ]
    }

  )},
  mounted() {

    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    const formattedDate = yesterday.toLocaleDateString('en-FI', options).toUpperCase();
    document.getElementById('date').innerHTML = formattedDate;
  }
}
</script>

<style scoped >

  img {
    max-width: 100%;
  }
  .title {
    min-height: 35vh;
    padding: 50px 0;
    background: url('../assets/alcotermo-product-banner.png') no-repeat center;
    background-size: cover;
    color: #fff;
  }
  .title .container {
    max-width: 1000px;
  }
  section {
    padding: 50px 0;
  }
  section:nth-child( 2n ) {
    background-color: rgb(245, 245, 245);
  }
  #section_1 {
    font-size: 1.3em;
  }
  #section_1 img {
    width: 400px;
  }
  #section_4 table {
    display: block;
    border: 1px solid #ddd;
    overflow-x: auto;
  }
  #section_4 table td {
    padding: 5px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
  #section_5 .card {
    border-radius: 0;
    align-items: center;
  }
  #section_5 .card .number {
    display: flex;
    justify-content: center;
    margin-right: .2em;
    font-weight: 300;
    min-width: 40px;
    font-size: 3em;
    color: #888;
    text-align: center;
    vertical-align: middle;
  }
  .bg-false {
    color: #fff;
    font-weight: 600;
    background-color: #c76161;
  }
  .bg-true {
    color: #fff;
    font-weight: 600;
    background-color: #69b249;
  }
</style>