import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Company from "@/views/Company.vue";
import Safety from "@/views/Safety.vue";
import HealthCare from "@/views/HealthCare.vue";
import DimecoSafe from "@/views/DimecoSafe.vue";
import DimecoCare from "@/views/DimecoCare.vue";
import DimecoCarePro from "@/views/DimecoCarePro.vue";
import NotFound from "@/views/NotFound.vue";
import T11 from "@/views/T11.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/solutions/comprehensive-occupational-health-and-safety",
    name: "Safety",
    component: Safety,
  },
  {
    path: "/solutions/solution-primary-care-24-7",
    name: "HealthCare",
    component: HealthCare,
  },
  {
    path: "/products/dimeco-safe",
    name: "DimecoSafe",
    component: DimecoSafe,
  },
  {
    path: "/products/dimeco-care",
    name: "DimecoCare",
    component: DimecoCare,
  },
  {
    path: "/products/dimeco-care-pro",
    name: "DimecoCarePro",
    component: DimecoCarePro,
  },
  {
    path: "/devices/t11",
    name: "T11",
    component: T11,
  },
  {
    path: "/company",
    name: "Company",
    component: Company,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes: routes,
  scrollBehavior() {
    // always scroll to top
    window.scrollTo({ top: 0 });
    return false;
  }
});

export default router;
