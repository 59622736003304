<template>

  <div class="title">
    <div class="container">
      <h1>Company</h1>
    </div>
  </div>

  <section id="section_1">
    <div class="container">
      <p>DIMECO is a technology company and we are open to collaboration with healthcare providers including medical universities.</p>
    </div>
  </section>


</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'CompanyPage',
  setup() {
    useMeta({
      title: 'Company',
    })
  }
}
</script>


<style scoped>
.title {
  min-height: 35vh;
  padding: 50px 0;
  background: url('../assets/slider3.jpg') no-repeat center;
  background-size: cover;
  color: #fff;
}
.title .container {
  max-width: 1000px;
}

section {
  padding: 50px 0;
}

section:nth-child(2n) {
  background-color: rgb(245, 245, 245);
}


</style>
