<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | DIMECO` : `DIMECO` }}</template>
  </metainfo>
  <MainHeader />
  <MainPage msg="Welcome to Your Vue.js App"/>

  <MainFooter />

</template>

<script>
import 'bootstrap'
import MainPage from './components/MainPage.vue'
import MainHeader from './components/Header.vue'
import MainFooter from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    MainHeader, MainPage, MainFooter
  }
}

// remove video controls for iphones
document.addEventListener('DOMContentLoaded', function () {
  var videoElements = document.querySelectorAll('video');

  videoElements.forEach(function(videoElement) {
    videoElement.controls = false;

    videoElement.addEventListener('play', function () {
      this.controls = false;
    });

    videoElement.addEventListener('pause', function () {
      this.controls = false;
    });
  });
});

</script>

<style>
#app {
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#app img {
  max-width: 100%;
}

</style>

<style lang="scss">
    @import "/src/assets/scss/_custom.scss";
    @import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
</style>