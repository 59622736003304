<template>

  <div class="title">
    <div class="container">
      <h1>Dimeco Care Pro</h1>
    </div>
  </div>

  <section id="section_1">
    <div class="container">

      <p>DIMECO CARE PRO is multifunctional and comprehensive system that cuts across health screening, identity verification personnel management and health database management and analysis. It integrates several industries and we are open to collaboration especially with healthcare providers including medical universities.</p>

      <p>The system is permanently developing and can be customised to specific customer’s needs.<br>
      Due to our own opinion health telemonitoring systems with the size of equipment matching the needs are the solutions implementing in every personal’s sphere of life.</p>
    </div>
  </section>
  <section id="section_2">
    <div class="container d-lg-flex flex-row gap-3 justify-content-between align-items-center">

      <div>
        <h4>THE MAIN CHARACTERISTICS ARE:</h4>
        <ul>
          <li>it is connected to the single computer center (cloud data storage);</li>
          <li>it has intuitive interface</li>
          <li>it has extra fast testing speed</li>
          <li>It is a universal complex that combines several diagnostic devices,</li>
          <li>it is mobile, and innovative methods of taking physiological indicators have been applied when creating this complex.</li>
        </ul>
      </div>

      <img class="d-block m-auto" src="../assets/dimeco-care-pro.png" alt="">

    </div>
  </section>
  <section id="section_3">

    <div class="container">
      <p>Our unique complex consisting of the device and a software is widely available to the masses, easy to operate and install.</p>

      <div class="row">

        <div class="col-md-6">
          <div class="card">
            <div class="card-header text-center">
              <h5>Bussiness center</h5>
            </div>
            <div class="card-image"><img src="../assets/care-pro-01.jpg" alt=""></div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header text-center">
              <h5>Mall</h5>
            </div>
            <div class="card-image"><img src="../assets/care-pro-02.jpg" alt=""></div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header text-center">
              <h5>Gas station</h5>
            </div>
            <div class="card-image"><img src="../assets/care-pro-03.png" alt=""></div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="card">
            <div class="card-header text-center">
              <h5>Railway station</h5>
            </div>
            <div class="card-image"><img src="../assets/care-pro-04.png" alt=""></div>
          </div>
        </div>

      </div>

    </div>

  </section>
  <section id="section_4">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <p>A non-contact vital signs monitoring and complaint questionnaires managefirst visit patient ‘s routings, improve service delivery in rural areas. Also it helps regular monitor follow-ups and people with limited mobility.</p>
        </div>
        <div class="col-lg-6">
          <div class="row align-items-center">
            <div class="col-6"><img src="../assets/care-pro-05.png" alt=""></div>
            <div class="col-6"><img src="../assets/care-pro-06.png" alt=""></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="section_5"></section>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
  name: 'DimecoCareProPage',
  setup() {
    useMeta({
      title: 'Dimeco Care Pro',
    })
  }
}
</script>


<style scoped>
.title {
  min-height: 35vh;
  padding: 50px 0;
  background: url('../assets/slider3.jpg') no-repeat center;
  background-size: cover;
  color: #fff;
}
.title .container {
  max-width: 1000px;
}

section {
  padding: 50px 0;
}

section:nth-child(2n) {
  background-color: rgb(245, 245, 245);
}

#section_2 {
  font-size: 1.2em;
}

#section_2 img {
  width: 400px;
}
#section_3 .row{
  margin-left: -1em;
  margin-right: -1em;
}
#section_3 .row [class*="col-"] {
  padding-left: 1em;
  padding-right: 1em;
}

#section_3 .card {
  border-radius: 0;
  margin-top: 2em;
}

</style>
