<template>
  <div class="container">
    <h1>About Page</h1>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
}
</script>

