<template>

  <!-- <section id="promo">
    <video src="../assets/video.mp4" class="video" autoplay muted loop width="100%" height="400px" style="object-fit: cover;">
    </video>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-7">
          <h2 class="text-center text-lg-start">T11</h2>
        </div>
        <div class="col-xl-5">
          <img src="../assets/photo-promo.png" class="d-block m-auto" alt="">

        </div>
      </div>

    </div>
  </section> -->

  <!-- <section id="section_1">
    <div class="container">
      <p>The ergonomic and compact design of the device is perfect for any workplace but is also protected from theft.</p>
    </div>
  </section> -->

  <section>
    <div class="container">
      <h1>T11</h1>
      <div class="row">
        <div class="col-lg-7 order-lg-1 content text-3"><p><strong>Our telemedicine device</strong> uses advanced technology to diagnose, monitor, treat, and rehabilitate patients. For integration with the Medbank platform, the DIMECO hardware and software complex is used.</p>
          <p>The device is highly technologically advanced and meets all modern quality standards. Allows for a wide range of medical procedures and examinations, visual inspection, and online communication. Provides convenience, efficiency and comfort for both medical staff and patients.</p>
          <p>Telemedicine equipment is a reliable assistant in the work of doctors and medical institutions, helps to improve the quality of services and improve the health of the population.</p>
          <p>The equipment is constantly being improved and updated to meet the latest advances in the field of medicine and provide the best result for patients.</p>
          </div>
        <div class="col-lg-5 order-lg-0"><img src="https://medbank.net/wp-content/uploads/2024/08/equipment-scaled.jpg" alt=""></div>
      </div>
    </div>
  </section>



  <ContactForm />

</template>

<script>
import { useMeta } from 'vue-meta'
import { ContactForm } from '@/components/ContactForm.vue'
export default {
  name: 't-11',
  components: {
    ContactForm
  },
  setup() {
    useMeta({
      title: 'T11',
    })
  }
}
</script>

<style scoped>

  img {
    max-width: 100%;
  }
section#promo {
  position: relative;
  height: 400px;
  padding-top: 2em;
  padding-bottom: 2em;
}

section#promo .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

section#promo .container {
  position: relative;
  z-index: 2;
  max-height: 100%;
}
section#promo .container img{
  width: 100%;
  max-width: 400px;
  height: inherit;
  max-height: 60%;
}

  section {
    padding: 50px 0;
  }
  section:nth-child( 2n +1 ) {
    background-color: rgb(245, 245, 245);
  }


</style>