<template>

  <section id="promo">
    <video src="../assets/video.mp4" class="video" playsinline autoplay muted loop width="100%" height="400px" style="object-fit: cover;">
    </video>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-7">
          <h2 class="text-center text-lg-start">Remote Patient Monitoring and Healthcare for&nbsp;business and&nbsp;individuals</h2>
        </div>
        <div class="col-xl-5">
          <img src="../assets/photo-promo.png" class="d-block m-auto" alt="">

        </div>
      </div>

    </div>
  </section>


  <!-- <Carousel :items-to-show="1" :wrapAround="true" :autoplay="7000">

    <Slide v-for="(slide, index) in Slides" :key="index">
      <div class="image" :style="`background-image: url(${slide.image});`"></div>
      <div class="container">
        <h2>{{slide.title}}</h2>
      </div>
    </Slide>

    <template #addons>
      <Navigation />
      <pagination />
    </template>

  </Carousel> -->

  <section id="about">
  <div class="container">
    <div class="row content">

      <h1>About DIMECO</h1>

      <div class="col-12 col-lg-7">

        <p>DIMECO is a well-organized and qualified team of engineering and IT professionals with six years of experience, engaged in a&nbsp;telemedicine project for round-the-clock remote health monitoring of&nbsp;employees of&nbsp;hazardous professions.</p>
        <p>We are developing telemedicine equipment and software, as well as collect data and provide an&nbsp;advanced analysis.<br/>
        We are going to provide our solutions to occupational health and safety companies as well as&nbsp;primary health care markets.</p>
        <p>The DIMECO solution consists of a&nbsp;device and software package that is widely available, easy to&nbsp;operate and install.</p>
        <p>The solution’s main characteristics are:</p>
        <ul>
          <li>connected to the single computer center (cloud data storage, protected channels);</li>
          <li>intuitive interface,</li>
          <li>extra fast testing speed (from 8 seconds to 1 minute).</li>
          <li>universal system that combines several diagnostic devices.</li>
        </ul>
        <p>We are open to collaborate.</p>

      </div>

      <div class="col-12 col-lg-5">
        <img src="../assets/cooperation.jpg" alt="Кооперация">
      </div>
    </div>
  </div>

  </section>

  <section id="products">
    <div class="solution container">

      <div class="col-xl-10 col-xxl-9 mx-auto">

        <h2>Devices for remote health monitoring</h2>

        <div class="card">
          <div class="card-content">
            <!-- <router-link to="/products/dimeco-care" class="product-link"><img src="../assets/dimeco-care.png" alt="DIMECO CARE"></router-link> -->
            <router-link to="/products/dimeco-care" class="product-link"><img src="../assets/photo-t11.jpg" alt="DIMECO CARE"></router-link>
            <div class="content">
              <router-link to="/products/dimeco-safe" class="nav-link"><h3 class="">DIMECO CARE</h3></router-link>
              <p>A non-contact physiological indicators monitoring system manages workplace risk assessment. With the help of the system businesses will be able to prevent access of sick, infected, intoxicated workers and unwanted visitors and track health trends for workplace assessment.</p>
            </div>
          </div>
        </div>

        <div class="card">
          <div class="card-content">
            <!-- <router-link to="/products/dimeco-safe" class="product-link"><img src="../assets/dimeco-safe.png" alt="DIMECO SAFE"></router-link> -->
            <router-link to="/products/dimeco-safe" class="product-link"><img src="../assets/photo-at.jpg" alt="DIMECO SAFE"></router-link>
            <div class="content">
              <router-link to="/products/dimeco-safe" class="nav-link"><h3 class="">DIMECO SAFE</h3></router-link>
              <p>Contactless occupational health and safety monitoring system</p>
              <p>A non-contact alcohol and thermal monitoring system manages workplace risk assessment . With the help of the system, businesses will be able to prevent access of sick, infected, intoxicated workers and unwanted visitors.</p>
            </div>
          </div>
        </div>

        <!--div class="card">
          <div class="card-content">
            <img src="../assets/dimeco-care-pro.png" alt="DIMECO SAFE PRO">
            <div class="content">
              <h3 class="">DIMECO CARE PRO</h3>
              <p>Initial self health assessment as a first step of primary care (GP)</p>
              <p>A non-contact psychological indicators monitoring and complaint questionnaires manage first visit patient ‘s routings, improve service delivery in rural areas. Also it helps regular monitor follow-ups and people with limited mobility</p>
            </div>
          </div>
        </div-->

      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <p>You can trust that you’re getting the best quality product with the best service guaranteed. <br>
      Product complies to all the essential Health, Safety and Environmental requirements established by the European Standards Agencies:</p>
      <ul>
        <li>Directive 2014/53/EU ( RED – Radio Equipment Directive);</li>
        <li>Directive RoHS 2011/65/EU;</li>
        <li>Directive 93/42/EEC (MDD - Medical Devices Directive).</li>
      </ul>
    </div>
  </section>

  <ContactForm />

</template>

<script>
import 'vue3-carousel/dist/carousel.css'
// import { Carousel, Slide } from 'vue3-carousel'
import { useMeta } from 'vue-meta'
import { ContactForm } from '@/components/ContactForm.vue'

export default {
  name: 'HomePage',
  components: {
    ContactForm
    // Carousel,
    // Slide,
    // Navigation,
    // Pagination
  },
  data: () => ({
    Slides: [
      { title: 'Encourage better employee health', image: '../img/slider1.jpg' },
      { title: 'Ensuring the possibility of primary medical care immediately in online mode', image: '../img/slider2.jpg' },
      // { title: 'Welcome to Your Vue.js App3', image: '../slider3.jpg' }
    ]
  }),
  setup() {
    useMeta({
      title: 'Home',
    })
  }
}
</script>

<style scoped>

section {
  padding: 50px 0;
}
section.carousel {
  padding: 0;
}

section#promo {
  position: relative;
  height: 400px;
  padding-top: 2em;
  padding-bottom: 2em;
}

section#promo .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}

section#promo .container {
  position: relative;
  z-index: 2;
  max-height: 100%;
}
section#promo .container img{
  width: 100%;
  max-width: 400px;
  height: inherit;
  max-height: 60%;
}

.content {
  text-align: left;
}

.carousel .image {
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.carousel ol li {
  min-width: 100%;
  width: 100%;
  height: 40vh;
}
.carousel ol li .container{
  position: relative;
  color: #fff;
  width: 100%;
}
#products {
  background-color: rgb(245, 245, 245);
}
.card {
  margin-bottom: 20px;
  padding: 10px 20px;
  border-radius: 0;
}
.card-content> * {
  text-align: center;
}
.card-content img {
  display: block;
  margin: auto;
  max-height: 200px;
}
#products .product-link {
  display: block;
  min-width: 200px;
  margin-right: 1em
}
#products .product-link,
#products .nav-link {
  filter: brightness(1);
  opacity: 1;
  transition: filter .3s ease, opacity .3s ease;
}
#products .product-link:hover,
#products .nav-link:hover {
  opacity: .8;
  filter: brightness(1.2)
}

@media screen and (min-width: 1200px) {
  section#promo {
      height: 450px;
  }

  section#promo .container,
  section#promo .container .row {
    height: 100%;
  }
  section#promo .container img{
    max-height: 100%;
    max-width: 100%;
  }

}
@media screen and (min-width: 992px) {

  .carousel ol li h2 {
    max-width: 800px;
    text-align: left;
  }
  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card-content> * {
  text-align: left;
  }
}
@media screen and (max-width: 991px) {
  .carousel ol li .container{
    max-width: 100%;
  }
}

</style>