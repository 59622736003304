<template>
  <section id="contact">
    <div class="container p-4">

      <form @submit="onSubmit" @reset="onReset" v-if="form.show" class="col-xl-10 col-xxl-9 mx-auto">
        <h2 class="mb-3">Email Us</h2>
        <input class="form-control mb-3" required type="text" name="your_name" placeholder="Name" v-model="form.your_name">
        <input class="form-control mb-3" required type="email" name="your_email" placeholder="Email" v-model="form.your_email">
        <input class="form-control mb-3" required type="tel" name="your_phone" placeholder="Phone" v-model="form.your_phone">
        <textarea class="form-control mb-3" name="your_message" placeholder="Message" v-model="form.your_message"></textarea>
        <div class="text-center">
          <button class="btn btn-primary m-3 mt-0" type="submit" variant="primary">Submit</button>
          <button class="btn btn-secondary m-3 mt-0" type="reset" variant="danger">Reset</button>
        </div>
      </form>

      <div class="congratulations" v-if="congratulations.show">
        <p class="text-center fs-4">Thank you for your message. We will get in touch with you as soon as possible.</p>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'

export default {
    name: 'ContactForm',
    data: () => ({
        form: {
            your_name: '',
            your_email: '',
            your_message: '',
            your_phone: '',
            show: true
        },
        congratulations: {
            show: false
        },

        onReset() {
            this.form.your_name = ''
            this.form.your_email = ''
            this.form.your_phone = ''
            this.form.your_message = ''
            this.form.show = true
        },
        onSubmit(e) {
            e.preventDefault();
            this.form.show = false
            this.congratulations.show = true

            axios
            .post('https://dimeco.fi/mail.php', 'data=' + JSON.stringify(this.form))
            .then((response) => {
                console.log(response.data)
            })
            .catch((error) => {
                console.log(error)
            })

        }
    })
}
</script>

<style scoped>
#contact {
  background-color: rgb(245, 245, 245);
}
</style>