<template>
    <div class="container">
        <h1 class="text-center">Error 404</h1>
        <p class="text-center">Page not found</p>
    </div>
</template>

<script>
import { useMeta } from 'vue-meta'

export default {
    name: 'NotFound',
    setup() {
        useMeta({
        title: 'Error 404',
        description: 'Page not found',
        })
    }
}
</script>