import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookieAcceptDecline from "vue-cookie-accept-decline"
import router from './router'
import { createMetaManager } from 'vue-meta'
import ContactForm from './components/ContactForm.vue'
import App from './App.vue'

createApp(App)
  .use(router)
  .use(createMetaManager())
  .use(VueAxios, axios)
  .component('ContactForm', ContactForm)
  .component("vue-cookie-accept-decline", VueCookieAcceptDecline)
  .mount("#app");
